export function getDefaultState() {
    return {
        userId: '',
        login: '',
        dealerName: '',
        email: '',
        phone: '',
        admin: {
            isAdmin: false,
            authorizedAdmin: false,
            loginLDAP: '',
        },
        needPasswordChange: false,
        loggedIn: false,
        //token: null,
        roles: [],
        error: false,
        hasNotifications: false,
        hasNews: false,
        isQuiz: false,
        autoCreateAccountButton: true,
        canSetSlot: false,
        canSetMaster: false,
    }
}

export default {
    ...getDefaultState()
}
export const STATUS_NEED_PASS_CHANGE = 11
export const STATUS_INACTIVE_USER = -4