import {getDefaultState} from './state'

export function SET_USER_LOGGED(state, sessionData) {
    console.log(sessionData)
    state.userId = sessionData.id
    state.login = sessionData.username
    state.dealerName = sessionData.dealername
    state.loggedIn = true
    state.needPasswordChange = sessionData.needPasswordChange
    state.phone = sessionData.phone
    state.email = sessionData.email
    state.autoCreateAccountButton = sessionData.createAccountButton
    state.canSetSlot = sessionData.canSetSlot
    state.canSetMaster = sessionData.canSetMaster
    state.roles = sessionData.roles

    if (typeof sessionData.authorizedAdmin !== 'undefined') {
        state.admin.isAdmin = true
        state.admin.authorizedAdmin = sessionData.authorizedAdmin
        state.admin.loginLDAP = sessionData.loginLDAP
    } else {
        state.admin.isAdmin = false
        state.admin.authorizedAdmin = false
        state.admin.loginLDAP = ''
    }
  
}

export function SET_ADMIN_LOGGED(state) {
    state.admin.authorizedAdmin = true
}

export function RESET_USER(state) {
    Object.assign(state, getDefaultState())
}

export function ERROR(state, errData) {
    state.error = errData
}

export function CLEAR_ERROR(state) {
    state.error = false
}

export function NEED_PASS_CHANGE(state) {
    state.needPasswordChange = true
}

export function PASS_CHANGED(state) {
    state.needPasswordChange = false
}

export function SET_HAS_NOTIFICATIONS(state) {
    state.hasNotifications = true
}

export function SET_HAS_NO_NOTIFICATIONS(state) {
    state.hasNotifications = false
}

export function SET_HAS_NEWS(state) {
    state.hasNews = true
}

export function SET_HAS_NO_NEWS(state) {
    state.hasNews = false
}

export function SET_IS_QUIZ(state, value) {
    state.isQuiz = value
}
