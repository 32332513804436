<template>
   <div class="content notifications">
      <div class="wrapper">
         <div class="wrapper__block">
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>
            <b-tabs @activate-tab="handleTabChange" v-model="selectTab">
              <b-tab title="Уведомления">
                <div class="table-notifications">
                  <div>
                    <div class="table-notifications__header">
                      <p>Дата и время</p>
                      <p>Номер заявки</p>
                      <p :style="{display: hide(767)}">Полный адрес</p>
                      <p :style="{display: hide(600)}">Переход из статуса</p>
                      <p>В статус</p>
                      <p :style="{display: hide(430)}"></p>
                    </div>

                    <div v-for="(item, index) of statusHistory"
                         :key="index"
                         class="table-notifications__body"
                    >
                      <div class="table-notifications__body-head"
                           v-b-toggle:v-bind="'collapse-request-' + index"
                      >
                        <p><label>{{ item.cdate }}</label></p>
                        <p><router-link :to="{
                                                name: item.link,
                                                params: {
                                                    guid: item.guid,
                                                    id: item.id,
                                                    inquiryid: item.inquiryid,
                                                }
                                              }"
                                        target="_blank"
                        >
                          {{ orderId(item) }}
                        </router-link></p>
                        <p :style="{display: hide(767)}">{{ item.address }}</p>
                        <p :style="{display: hide(600)}">{{ item.last_status }}</p>
                        <p>{{ item.current_status }}</p>
                        <p class="sphere" :style="{'background-color': item.color, display: hide(430)}"></p>
                        <div class="table-notifications__arrow"></div>
                      </div>
                      <b-collapse :id="'collapse-request-' + index"
                                  v-model="item.collapse"  v-on:shown="showDesc(item)"
                      >
                        <div class="table-notifications__body-desc">
                          <div class="table-notifications__row">
                            <p>Адрес</p>
                            <p>{{ item.address }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Клиент</p>
                            <p>{{ item.client }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Статус</p>
                            <p>{{ item.current_status }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Слот</p>
                            <p>{{ item.slot }}</p>
                          </div>

                          <div class="table-notifications__row">
                            <p>Комментарий</p>
                            <p>{{ item.comment }}</p>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="История просмотров">
                   <ul class="notifications__block">
                       <li v-for="(item) of history" :key="item.id">
                           <span>
                               Заявка
                               <router-link :to="{
                                   name: item.link,
                                   params: {
                                       guid: item.object_id,
                                       id: item.object_id,
                                   }
                                }">
                                   {{ item.object_name }}
                               </router-link>
                               от {{ item.object_cdate }} {{ item.action }}
                           </span>
                       </li>
                       <!--                     <li>Заявки-->
                       <!--                        <router-link :to="{name: ''}">Req2233445</router-link>-->
                       <!--                        от 15.09.2020 создана-->
                       <!--                     </li>-->
                       <!--                     <li>Заявки-->
                       <!--                        <router-link :to="{name: ''}">Req2233444</router-link>-->
                       <!--                       от 15.09.2020 просмотрена-->
                       <!--                     </li>-->

                   </ul>
               </b-tab>
            </b-tabs>


         </div>
      </div>
   </div>
</template>

<script>
import {mapActions} from "vuex";
import urls from "@/configs/urls";
import resizeWin from "@/mixins/resizeWin";
import eventBus from "@/eventBus";

const TAB_CHSTATUS = 0
const TAB_HISTORY = 1

const TYPE_FMC = 'fmc'
const TYPE_UNITE = 'unite'

// eslint-disable-next-line no-unused-vars
const HISTORY_OBJECT_TYPE_XRM = 1
const HISTORY_OBJECT_TYPE_FMC = 2
const HISTORY_OBJECT_TYPE_UNITE = 3

const HISTORY_ACTION_TYPE_VIEW = 1
// eslint-disable-next-line no-unused-vars
const HISTORY_ACTION_TYPE_CREATE = 2


export default {
    name: "notifications",

    data() {
        return {
            tabNum: TAB_CHSTATUS,
            notifications: [],
            history: [],
            statusHistory: [],
            checkedNotifications: [],
            newNotifications: [],
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                }, {
                    text: 'Уведомления',
                    active: true
                }
            ],
        }
    },
    mixins: [resizeWin],
    computed: {
        chStatusNotifications() {
          return this.statusHistory
        },

        selectTab: {
            get() {
                return TAB_CHSTATUS
            },
            set(val) {
                this.tabNum = val
            }
        },
    },
    methods: {
        ...mapActions('user', ['doGetNotifications', 'doGetActionHistory', 'doMarkNotifications', 'doCheckUserHasNotifications', 'doGetStatusDetails', 'doGetStatusHistory']),
        handleTabChange(tabIndex) {
            this.checkedNotifications = []
            switch (tabIndex) {
                case TAB_CHSTATUS:
                  this.loadStatusHistory()
                  break
                case TAB_HISTORY:
                    this.loadHistory()
                    break
            }
        },
        orderId(item) {
          return item.reqno
        },
        getLinkByObjectType(type) {
            switch (type) {
                case HISTORY_OBJECT_TYPE_FMC:
                    return urls.FMC_VIEW_DEMAND_ROUTE
                case HISTORY_OBJECT_TYPE_XRM:
                    return urls.XRM_VIEW_DEMAND_ROUTE
                case HISTORY_OBJECT_TYPE_UNITE:
                    return urls.UNITE_VIEW_DEMAND_ROUTE
            }
        },
        parseHistory(item) {
            return {
                ...item,
                ...{
                    link: this.getLinkByObjectType(item.object_type),
                    action: item.action_type === HISTORY_ACTION_TYPE_VIEW ? 'просмотрена' : 'создана'
                }
            }
        },
        parseStatus(notification) {
          return {
            ...notification,
            ...{link: notification.type === TYPE_FMC ? urls.FMC_VIEW_DEMAND_ROUTE : (notification.type === TYPE_UNITE ? urls.UNITE_VIEW_DEMAND_ROUTE : urls.XRM_VIEW_DEMAND_ROUTE)},
            ...{updated: false},
            ...{client:  '-'},
            ...{slot:    '-'},
            ...{comment: '-'}

          }
        },
        async loadHistory() {
            const history = await this.doGetActionHistory()
                .catch(() => [])

            this.history = history.map((item) => this.parseHistory(item))
        },
        async loadStatusHistory() {
          const notifications = await this.doGetStatusHistory()
              .catch(() => [])

          const counter = notifications.filter((item) => !item.viewed)
          if (counter.length > 0) {
            this.$store.commit('user/SET_HAS_NOTIFICATIONS')
          } else {
            this.$store.commit('user/SET_HAS_NO_NOTIFICATIONS')
          }

          this.statusHistory = notifications.map((item) => this.parseStatus(item))
        },
        async showDesc(item) {
            if (item.updated) {
                return
            }

            const data = await this.doGetStatusDetails({
                id: item.id,
                incident: item.guid,
                type: item.type,
            })
                .then(r => r.data ?? {})
            item.viewed = true

            item.address = data.gm_address
            item.client = data.gm_fio
            item.status = data.sd_substatusid
            item.slot = data.gm_slot;
            item.comment = data.comment;
            item.updated = true

            const orders = this.statusHistory.filter((item) => !item.viewed)
            if (orders.length === 0) {
              this.$store.commit('user/SET_HAS_NO_NOTIFICATIONS')
            }

        }
    },
    mounted() {
        this.loadStatusHistory()
        eventBus.$on('custom-event', (event) => {
          this.statusHistory.unshift(event)
        })
    },
}
</script>

<style lang="scss">
  .notifications {
    padding: 18px 0 37px;
  }

   .breadcrumb {
      margin-bottom: 34px;
   }

   .tab-content {
      padding-top: 30px;
   }

   .big-green-title {
      margin-bottom: 34px;
   }

   .buttons {
       text-align: left;
       flex-grow: 1;

       .btn {
           margin-right: 20px;
           margin-bottom: 20px;
           border-radius: 0;
       }
   }

   .notifications__block {
      margin-bottom: 0;

      li {
         display:  flex;
         flex-direction: column;
         position: relative;
         margin-bottom: 27px;
         line-height: 20px;
         padding-left: 30px;


         &:last-child {
            margin-bottom: 0;
         }
      }

       li.new {
           font-weight: bold;
       }

       li.new input[type=checkbox]{
           height: auto;
           position: absolute;
           left: 0;
           top: 2px;
       }

      li:not(.new):before {
         content: '— ';
         position: absolute;
         left: 0;
      }

      a {
         color: var(--mf-blue);
      }
   }

    .table-notifications {
      position: relative;

      a {
        color: var(--mf-blue);
      }

      .table-notifications__header {
        display: flex;
        color: var(--mf-gray);
        font-size: 14px;
        line-height: 20px;
        padding-left: 35px;
        margin-bottom: 20px;
      }

      .sphere {
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }

      .table-notifications__body {
        border: 1px solid #D9D9D9;
        margin-top: -1px;
      }

      .table-notifications__body-head {
        display: flex;
        align-items: center;
        padding: 18px 0 18px 35px;
        position: relative;
        line-height: 20px;
      }

      .table-notifications__body-head.new p {
        font-weight: bold;
      }

      .table-notifications__body-desc {
        background-color: #F7F7F7;
        border-top: 1px solid #D9D9D9;
        padding: 25px 25px 30px 70px;
        line-height: 18px;
        word-break: break-word;

        .table-notifications__row {
          display: flex;
          margin-bottom: 23px;
        }

        p:first-child {
          color: var(--mf-gray);
          width: 196px;
          flex-shrink: 0;
          padding-right: 20px;
        }

        .btn {
          //margin-left: -8px;
        }

      }

      .table-notifications__arrow {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        background: url('../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
        transform: rotate(90deg);
        position: absolute;
        left: 5px;
      }

      .table-notifications__body-head.not-collapsed {
        .table-notifications__arrow {
          transform: rotate(-90deg);
        }
      }
    }

    .table-notifications__header,
    .table-notifications__body-head {
      p {
        padding-right: 10px;
        flex-shrink: 0;
      }
    }

      @media (min-width: 1200px) {
        .table-notifications__header,
        .table-notifications__body-head {
          p:first-child {
            width: 110px;
          }

          p:nth-child(2) {
            width: 110px;
          }

          p:nth-child(3) {
            width: 340px;
          }

          p:nth-child(4) {
            width: 175px;
          }

          p:nth-child(5) {
            width: 175px;
          }

          p:nth-child(6) {
            width: 20px;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 1199px) {
        .table-notifications__header,
        .table-notifications__body-head {
          p:first-child {
            width: 20%;
          }

          p:nth-child(2) {
            width: 20%;
          }

          p:nth-child(3) {
            width: 20%;
          }

          p:nth-child(4) {
            width: 20%;
          }

          p:nth-child(5) {
            width: 15%;
          }
        }
      }
        @media (max-width: 767px) {
          .wrapper__block {
            padding: 20px 15px 35px;
          }

          .breadcrumb {
            margin-bottom: 17px;
          }

          .tab-content {
            padding-top: 30px;
          }

          .big-green-title {
            margin-bottom: 27px;
          }

            .table-notifications__header,
            .table-notifications__body-head {
                padding-left: 15px;
            }

            .table-notifications__body-desc {
                padding: 25px 15px 30px;
            }

            .table-notifications__header {
                margin-bottom: 12px;
            }

          .table-notifications__header,
          .table-notifications__body-head {
            p:first-child {
              width: 20%;
            }

            p:nth-child(2) {
              width: 20%;
            }

            p:nth-child(4) {
              width: 25%;
            }

            p:nth-child(5) {
              width: 25%;
            }
          }

          .table-notifications {
            .table-notifications__body-desc {
              padding: 10px  10px  10px  10px;

              p:first-child {
                width: 115px;
              }
            }
          }

            .table-notifications__body-desc .table-tariff__row {
                margin-bottom: 22px;
            }

            .table-notifications__arrow {
                right: 7px;
            }
        }

        @media (max-width: 600px) {
          .table-notifications__header,
          .table-notifications__body-head {
            p:first-child {
              width: 35%;
            }

            p:nth-child(2) {
              width: 30%;
            }

            p:nth-child(4) {
              width: 25%;
            }

            p:nth-child(5) {
              width: 30%;
            }
          }
        }

        @media (max-width: 480px) {
          .table-notifications__header,
          .table-notifications__body-head {
            p:first-child {
              width: 30%;
            }

            p:nth-child(2) {
              width: 30%;
            }

            p:nth-child(4) {
              width: 25%;
            }

            p:nth-child(5) {
              width: 35%;
            }
          }
        }
</style>