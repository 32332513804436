import axios from "@/axios";

export default {
    async login(username, password) {
        const data = new FormData()
        data.append('username', username)
        data.append('password', password)


        return axios.post('/api/ajax/login', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async loginAdmin(password) {
        const data = new FormData()
        data.append('password_ldap', password)
        return axios.post('/api/ajax/ldap', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async logout() {
        return axios.get('/api/ajax/logout', {
            withCredentials: true,
        })
    },

    async expiredChangePass(currentPass, newPass, newPassRepeat) {
        const data = new FormData()
        data.append('password', currentPass)
        data.append('newpassword', newPass)
        data.append('newpasswordrepeat', newPassRepeat)

        return axios.post('/api/ajax/expired', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async changePass(currentPass, newPass, newPassRepeat) {
        const data = new FormData()
        data.append('cur_pwd', currentPass)
        data.append('new_pwd', newPass)
        data.append('new_pwd_repeat', newPassRepeat)

        return axios.post('/api/ajax/changepassword', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async sessionData() {
        return axios.get('/api/ajax/sessiondata')
    },

    async forgotPass(param, value) {
        const data = new FormData()
        data.append('param', param)
        data.append('value', value)

        return axios.post('/api/ajax/fogotpassword', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async recoveryUser(code, publicCode) {
        return axios.get('/api/ajax/recoveryuser', {
            params: { code, publicCode }
        })
    },

    async newPassLogin(pass1, pass2, code) {
        const data = new FormData()
        data.append('pass1', pass1)
        data.append('pass2', pass2)
        data.append('code', code)

        return axios.post('/api/ajax/newpasslogin', data, {
            'Content-Type': 'multipart/form-data',
        })
    },

    async checkUserHasNotifications() {
        return axios.get('/api/xajax/getnotify?count=true&version=v2')
    },

    async getNotifications() {
        return axios.get('/api/xajax/getnotify?version=v2')
    },

    async getActionHistory() {
        return axios.get('/api/xajax/getactionshistory')
    },

    async getStatusDetails(incident, id, type) {
        return axios.get('/api/xajax/getstatusdetails', {
            params: {
                guid: incident,
                id: id,
                type: type,
            }
        })
    },

    async getStatusHistory() {
        return axios.get('/api/xajax/getnotifystatus')
    },

    markNotifications(guids) {
        const data = new FormData()
        guids.forEach((guid) => {
            data.append('guids[]', guid)
        })

        return axios.post('/api/xajax/marknotify', data, {
            'Content-Type': 'multipart/form-data',
        })
    },

    async getUnblockSms(phone, login) {
        return axios.get('/api/ajax/getunblocksms/', {
            params: {
                login,
                phone
            }
        })
    },

    async unblockUser(code, publicCode) {
        return axios.get('/api/ajax/unblockuser/', {
            params: {
                code,
                publicCode
            }
        })
    },

    async getClosedOrders() {
        return axios.get('/api/xajax/getclosedorders')
    },

    async getActiveOrders() {
        return axios.get('/api/xajax/getactiveorders')
    },

    async getOrdersList(status, period) {
        return axios.get('/api/xajax/getorderslist', {
            params: {
                status,
                period
            }
        })
    },
}