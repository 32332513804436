var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content notifications"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper__block"},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('p',{staticClass:"big-green-title"},[_vm._v(_vm._s(_vm.breadcrumb[_vm.breadcrumb.length -1].text))]),_c('b-tabs',{on:{"activate-tab":_vm.handleTabChange},model:{value:(_vm.selectTab),callback:function ($$v) {_vm.selectTab=$$v},expression:"selectTab"}},[_c('b-tab',{attrs:{"title":"Уведомления"}},[_c('div',{staticClass:"table-notifications"},[_c('div',[_c('div',{staticClass:"table-notifications__header"},[_c('p',[_vm._v("Дата и время")]),_c('p',[_vm._v("Номер заявки")]),_c('p',{style:({display: _vm.hide(767)})},[_vm._v("Полный адрес")]),_c('p',{style:({display: _vm.hide(600)})},[_vm._v("Переход из статуса")]),_c('p',[_vm._v("В статус")]),_c('p',{style:({display: _vm.hide(430)})})]),_vm._l((_vm.statusHistory),function(item,index){return _c('div',{key:index,staticClass:"table-notifications__body"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle:v-bind",value:('collapse-request-' + index),expression:"'collapse-request-' + index",arg:"v-bind"}],staticClass:"table-notifications__body-head"},[_c('p',[_c('label',[_vm._v(_vm._s(item.cdate))])]),_c('p',[_c('router-link',{attrs:{"to":{
                                             name: item.link,
                                             params: {
                                                 guid: item.guid,
                                                 id: item.id,
                                                 inquiryid: item.inquiryid,
                                             }
                                           },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.orderId(item))+" ")])],1),_c('p',{style:({display: _vm.hide(767)})},[_vm._v(_vm._s(item.address))]),_c('p',{style:({display: _vm.hide(600)})},[_vm._v(_vm._s(item.last_status))]),_c('p',[_vm._v(_vm._s(item.current_status))]),_c('p',{staticClass:"sphere",style:({'background-color': item.color, display: _vm.hide(430)})}),_c('div',{staticClass:"table-notifications__arrow"})]),_c('b-collapse',{attrs:{"id":'collapse-request-' + index},on:{"shown":function($event){return _vm.showDesc(item)}},model:{value:(item.collapse),callback:function ($$v) {_vm.$set(item, "collapse", $$v)},expression:"item.collapse"}},[_c('div',{staticClass:"table-notifications__body-desc"},[_c('div',{staticClass:"table-notifications__row"},[_c('p',[_vm._v("Адрес")]),_c('p',[_vm._v(_vm._s(item.address))])]),_c('div',{staticClass:"table-notifications__row"},[_c('p',[_vm._v("Клиент")]),_c('p',[_vm._v(_vm._s(item.client))])]),_c('div',{staticClass:"table-notifications__row"},[_c('p',[_vm._v("Статус")]),_c('p',[_vm._v(_vm._s(item.current_status))])]),_c('div',{staticClass:"table-notifications__row"},[_c('p',[_vm._v("Слот")]),_c('p',[_vm._v(_vm._s(item.slot))])]),_c('div',{staticClass:"table-notifications__row"},[_c('p',[_vm._v("Комментарий")]),_c('p',[_vm._v(_vm._s(item.comment))])])])])],1)})],2)])]),_c('b-tab',{attrs:{"title":"История просмотров"}},[_c('ul',{staticClass:"notifications__block"},_vm._l((_vm.history),function(item){return _c('li',{key:item.id},[_c('span',[_vm._v(" Заявка "),_c('router-link',{attrs:{"to":{
                                name: item.link,
                                params: {
                                    guid: item.object_id,
                                    id: item.object_id,
                                }
                             }}},[_vm._v(" "+_vm._s(item.object_name)+" ")]),_vm._v(" от "+_vm._s(item.object_cdate)+" "+_vm._s(item.action)+" ")],1)])}),0)])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }