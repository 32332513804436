<template>
    <div class="request__info request">
        <div class="wrapper">
            <div v-if="!error" class="wrapper__block">
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

                <p class="big-green-title">{{ breadcrumb[breadcrumb.length - 1].text }}</p>

                <div class="tab-pane__title">
                    {{ demandData.guid }}
                </div>

                <div class="info__content">
                    <div class="info__row">
                        <p>Дата</p>
                        <p>{{ demandData.sd_registrationdate }}</p>
                    </div>

                    <div class="info__row">
                        <p>Дата последнего изменения</p>
                        <p>{{ demandData.ccmp_last_modified }}</p>
                    </div>

                    <!--<div class="info__row">-->
                        <!--<p>Город</p>-->
                        <!--<p>{{ demandData.city }}</p>-->
                    <!--</div>-->

                    <!--                    <div class="info__row">-->
                    <!--                        <p>Номер CCMP</p>-->
                    <!--                        <p>{{ demandData.gm_ccmpguid }}</p>-->
                    <!--                    </div>-->

                    <div class="info__row">
                        <p>Тип</p>
                        <p>{{ demandData.gm_incidentfor }}</p>
                    </div>

                    <div class="info__row">
                        <p>Дилер</p>
                        <p>{{ demandData.gm_dealerorganizationname }}</p>
                    </div>

                    <div class="info__row">
                        <p>Создал</p>
                        <p>{{ demandData.gm_userloginnbnd }}</p>
                    </div>

                    <div class="info__row">
                        <p>Глобальный статус</p>
                        <p>{{ demandData.statecode }}</p>
                    </div>

                    <div class="info__row">
                        <p>Статус</p>
                        <p>{{ demandData.sd_substatusid }}</p>
                    </div>

                    <div class="info__row">
                        <p>Процесс нахождения заявки в CCMP</p>
                        <p>{{ demandData.ccmp_activity_name | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Сопроводительная записка</p>
                        <p>{{ demandData.ccmp_forward_note | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Комментарий в CCMP</p>
                        <p>{{ demandData.ccmp_description | nullAsDash }}</p>
                    </div>


                    <div class="info__row">
                        <p>Дата закрытия</p>
                        <p>{{ demandData.sd_closedate | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Адрес клиента</p>
                        <p>{{ demandData.gm_address | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Способ доставки</p>
                        <p>{{ deliveryMethod | nullAsDash }}</p>
                    </div>

                    <div class="info__row" v-if="demandData.delivery_method === 'courier'">
                        <p>Адрес доставки</p>
                        <p>{{ demandData.delivery_address | nullAsDash }}</p>
                    </div>

                    <div class="info__row" v-if="demandData.delivery_method === 'courier'">
                        <p>Дата и время доставки</p>
                        <p>{{ demandData.delivery_date | nullAsDash }}</p>
                    </div>

                    <div class="info__row" v-if="demandData.delivery_method !== 'courier'">
                        <p>Адрес салона</p>
                        <p>{{ demandData.delivery_address | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>ФИО</p>
                        <p>{{ demandData.gm_fio | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>E-mail</p>
                        <p>{{ demandData.gm_email | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Лицевой счет</p>
                        <p>{{ demandData.gm_accountnumber | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Номер заказа в ИМ МФ</p>
                        <p>{{ demandData.reqnum | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                      <p>ICCI</p>
                      <p>{{ demandData.icci | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                      <p class="info__row-button">Статус заявки в ИМ</p>
                      <p class="info__row-button">{{ demandData.orderStatus | nullAsDash }}</p>

                      <button class="btn-refresh"
                              title="Получить статус заказа"
                              @click="refreshStatus"
                              v-show="demandData.orderStatus"
                      ></button>
                    </div>

                    <div class="info__row">
                      <p>Код ошибки</p>
                      <p>{{ demandData.resultCode | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                      <p>Ошибка</p>
                      <p>{{ demandData.resultText | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Скидка</p>
                        <p>{{ demandData.discount_name | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Пакет при регистрации</p>
                        <p>{{ demandData.saved_discount.name | nullAsDash }}</p>
                    </div>

                    <div class="info__row info__row_table">
                        <p>Продукты при регистрации</p>
                        <ViewingMoreSimplifiedTable :discount_amount = "demandData.gm_monthlypayment" :discount_onetime_amount = "demandData.gm_singlepayment_discounted" :items="savedTariffs"/>
                    </div>

                    <div class="info__row">
                        <p>Сумма по тарифу</p>
                        <p>{{ demandData.saved_discount.cost | nullAsDash }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма активации</p>
                        <p>{{ demandData.gm_singlepayment }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма активации со скидкой</p>
                        <p>{{ demandData.gm_singlepayment_discounted }} ₽</p>
                    </div>

                    <div class="info__row">
                        <p>Сумма ежемесячных списаний</p>
                        <p>{{ demandData.gm_monthlypayment }} ₽</p>
                    </div>

                    <!--                    <div class="info__row">-->
                    <!--                        <p>Пакет</p>-->
                    <!--                        <p>{{ demandData.gm_discount | nullAsDash }}</p>-->
                    <!--                    </div>-->

                    <!--<div class="info__row info__row_table">-->
                        <!--<p>Продукт</p>-->
                        <!--<ViewingMoreTable :items="productList"/>-->
                    <!--</div>-->

                    <div class="info__row">
                        <p>Мобильный телефон</p>
                        <p>{{ demandData.gm_mobile | nullAsDash }}</p>
                    </div>

                    <div class="info__row">
                        <p>Комментарий при создании (подробное описание)</p>
                        <p style="word-break: break-word;">{{ demandData.gm_comment | nullAsDash }}</p>
                    </div>

                    <!--                    <div class="info__row">-->
                    <!--                        <p>Канал продаж</p>-->
                    <!--                        <p>{{ demandData.sale_channel | nullAsDash }}</p>-->
                    <!--                    </div>-->


                </div>
            </div>
            <div v-else class="wrapper__block">
                <div class="red-desc">
                    <p>{{ error }}</p>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
// import ViewingMoreTable from "@/components/viewing/ViewingMoreTable";
import {mapActions} from "vuex";
import ViewingMoreSimplifiedTable from "@/components/viewing/ViewingMoreSimplifiedTable";

export default {
    name: "viewingUniteRequests_more",
    components: {
        ViewingMoreSimplifiedTable,
        // ViewingMoreTable,
    },
    data() {
        return {
            demandId: null,
            demand: {},
            error: null,
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Просмотр заявок',
                    to: {name: 'viewingRequests'}
                }, {
                    text: 'Заявка на подключение Мобильного домашнего интернета',
                    active: true
                }
            ],
        }
    },
    computed: {
        demandData() {
            const demand = {
                ...this.demand
            }
            switch (demand.gm_incidentfor) {
                case '930660000':
                    demand.gm_incidentfor = 'Новый клиент';
                    break;
                case '930660001':
                    demand.gm_incidentfor = 'Существующий клиент';
                    break;
                default:
                    demand.gm_incidentfor = '-';
                    break;
            }

            switch (demand.statecode) {
                case '0':
                    demand.statecode = 'Активный';
                    break;
                case '1':
                    demand.statecode = 'Разрешено';
                    break;
                case '2':
                    demand.statecode = 'Отменено';
                    break;
                default:
                    demand.statecode = 'Активный';
                    break;
            }

            return demand
        },

        deliveryMethod() {
            switch (this.demandData.delivery_method) {
                case 'courier':
                    return 'Курьер';
                case 'cse':
                    return 'Самовывоз';
                default:
                    return 'Самовывоз';
            }
        },

        savedTariffs() {
            const demand = {...this.demandData}

            if (demand.saved_tariffs === undefined || demand.saved_tariffs === null) {
                return []
            }

            return demand.saved_tariffs.map((item) => {
                return {
                    name: item.name,
                    monthly_cost: (item.period !== "1") ? '0.00' : Number(item.cost * item.count).toFixed(2),
                    daily_cost: (item.period !== "2") ? '0.00' : Number(item.cost * item.count).toFixed(2),
                    onetime_cost: (item.packId < 0) ? Number(item.cost * item.count).toFixed(2) : '0.00',
                    count: ((item.count === null) ? '0' : Number(item.count)),
                }
            })

        },

        productList() {
            const demand = {...this.demand}

            if (demand.gm_product === undefined) {
                return []
            }
            let result = []
            for (let i = 0; i < demand.gm_product.length; i++) {
                result.push({
                    name: demand.gm_tariffs[i],
                    type: demand.gm_product[i],
                    monthly_cost: ((demand.gm_monthlypayment_list[i] === null) ? '0.00' : Number(demand.gm_monthlypayment_list[i]).toFixed(2)),
                    monthly_discounted_cost: ((demand.gm_monthlypayment_list[i] === null) ? '0.00' : Number(demand.gm_monthlypayment_list[i]).toFixed(2)),
                    single_cost: ((demand.gm_singlepayment_list[i] === null) ? '0.00' : Number(demand.gm_singlepayment_list[i]).toFixed(2)),
                })

            }

            return result
        },
        notRefresh() {
          return false
        }
    },

    async mounted() {
        this.demandId = this.$route.params.inquiryid
        console.log(this.$route.params)
        this.demand = await this.doGetDetail({id: this.$route.params.inquiryid})
            .then(r => r.data)
            .catch((r) => {
                this.error = r.message
                return null
            })

    },
    methods: {
        ...mapActions('OEveryWhere', ['doGetDetail', 'doGetStatus']),

        async refreshStatus() {
          await this.doGetStatus({id: this.demand.reqnum, fias: this.demand.fias})
              .then(r => {
                this.demand.status = r.data.status
              })
              .catch((r) => {
                this.error = r.message
                return null
              })
        },
    },
    filters: {
        nullAsDash: (str) => ((str && str !== '') ? str : '-'),
    }
}
</script>

<style lang="scss" scoped>
.request__info {

  .red-desc {
    background-color: #FBDED9;
    color: var(--mf-red);
    font-size: 12px;
    line-height: 20px;
    //margin: -20px -84px 17px;
    padding: 12px 84px;

    p:first-child {
      margin-bottom: 5px;
    }
  }

  .info__content {
    padding-top: 15px;
  }

  .info__btns {
    height: 0;
    margin-top: -13px;
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-right: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .info__row {
    display: flex;
    margin-bottom: 27px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    p.info__row-button {
      line-height: 30px;
    }

    p:first-child {
      width: 260px;
      flex-shrink: 0;
    }
  }

  .info__row_table {
    margin-bottom: 0 !important;

    @media (max-width: 840px) {
      flex-wrap: wrap;

      .table-tariff {
        margin-top: 30px;
      }
    }
  }

  .btns {
    display: flex;
    flex-wrap: wrap;
  }

  .row-btn-back {
    margin-right: auto;
  }

  .btn.cancel {
    margin-right: 20px;
  }

  .btn-refresh {
    margin-left: 10px;
    cursor: pointer;
    width: 34px;
    height: 30px;
    background: url('../../../assets/svg/refresh.svg') center / 30px no-repeat;

    &:disabled {
      opacity: 0.3;
    }
  }

  @media (max-width: 767px) {

    .info__content {
      padding-top: 0;
      margin-top: -5px;
    }

    .info__btns {
      height: auto;
      margin-top: 0;

      .btn:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    .info__row {
      margin-bottom: 29px;

      p:first-child {
        width: 150px;
        padding-right: 30px;
      }
    }

    .btns {
      justify-content: space-between;
    }

    .btn:not(.back) {
      margin-bottom: 30px;
    }

    .row-btn-back {
      order: 1;
      width: 100%;
    }
  }

}


</style>