import API from "@/api"
//import { wrapPromiseWithLoader, applyWrappersToPromise, catchServerError } from "@/utils/ui"
//import { handleErrorWithModal } from "@/utils/errors"
import {finishLoading, startLoading} from "@/utils/ui";
import {checkForErrorResponse} from "@/utils/errors";
import vueInstance from '@/main'

export async function doAuth(ctx, {username, password}) {


    startLoading()
    return API.auth.login(username, password)
        .then((r) => r.data)
        .then(r => checkForErrorResponse(r))
        .catch((e) => {
            console.log(e)
            return Promise.reject(e)
        })
        .finally(finishLoading)

}

export function doAdminAuth({dispatch}, {password}) {

    startLoading()
    
    return API.auth.loginAdmin(password)
    .then((r) => {
        const {data} = r.data
        if (data.result < 1) {
            return Promise.reject(r.data)
        }
        else {
            dispatch('adminAuthSuccess')
            return Promise.resolve(r.data)
        }
        
    })
    .finally(finishLoading)
}

export async function loadUserSession({commit}) {

    startLoading()
    return API.auth.sessionData()
        .then((r => r.data))
        .then(r => {
            if (r && r.status > 0) {
                commit('SET_USER_LOGGED', r.data.session)
                return Promise.resolve(r)
            } 
            return Promise.reject(r)
        })
        .catch((e) => {
            e.response
            return false
        })
        .finally(finishLoading)

}

export async function doForgotPass(ctx, {param, value}) {
    //const forgotPassRequest = applyWrappersToPromise(dispatch, API.auth.forgotPass(param, value), [wrapPromiseWithLoader, catchServerError])
    startLoading()
    return API.auth.forgotPass(param, value)
        .then((r) => r.data)
        .then((r) => {
            if (r.status) {
                return Promise.resolve(r)
            } else {
                return Promise.reject(r.message)
            }
        })
        .finally(finishLoading)
}

export async function doRecoveryUser(ctx, { code, publicCode }) {
    startLoading()
    return API.auth.recoveryUser(code, publicCode)
        .then(r => r.data)
        .then((r) => {
            console.log(r)
            if (r.data.status) { // !!! r.data.status NOT r.status as usual
                return Promise.resolve(r)
            } else {
                return Promise.reject(r)
            }
        })
        .finally(finishLoading)
}

export function doLogout({commit}) {

    //const logoutRequest = applyWrappersToPromise(dispatch, API.auth.logout(), [wrapPromiseWithLoader, catchServerError])
    startLoading()
    return API.auth.logout()
    .then((r) => {
        if (r.status) {
            commit('RESET_USER')
            return Promise.resolve(r)
        }

        return Promise.reject(r.message)
        
    })
    .finally(finishLoading)
}

export function doNewPassLogin(ctx, { pass1, pass2, code }) {
    startLoading()

    return API.auth.newPassLogin(pass1, pass2, code)
        .then(r => r.data)
        .then((r) => {
            if (r.data.result > 0) {
                return Promise.resolve(r)
            }

            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doExpiredChangePass({dispatch}, {currentPass, newPass, newPassRepeat}) {
    startLoading()
    return API.auth.expiredChangePass(currentPass, newPass, newPassRepeat)
        .then(r => r.data)
        .then(r => checkForErrorResponse(r))
        .then(() => { dispatch('userPassChanged') })
        .finally(finishLoading)
}

export function doChangePass(ctx, {currentPass, newPass, newPassRepeat}) {
    startLoading()
    return API.auth.changePass(currentPass, newPass, newPassRepeat)
        .then(r => r.data)
        .then(r => checkForErrorResponse(r))
        .finally(finishLoading)
}

export function doCheckUserHasNotifications({commit}) {
    return API.auth.checkUserHasNotifications()
        .then(r => r.data)
        .then((r) => {
            if (r.data.news !== undefined) {
                if (r.data.news > 0) {
                    commit('SET_HAS_NEWS')
                    if (r.data.news >= 1) {
                        vueInstance.then(vue => {
                            if (!['news', 'news_suspended'].includes(vue.$route.name)) {
                                vue.$bvModal.show('modalViewNews')
                            }
                        })
                    }
                }
            } else {
                commit('SET_HAS_NO_NEWS')
            }

            if (r.data.count !== undefined) {
                if (r.data.count > 0) {
                    commit('SET_HAS_NOTIFICATIONS')
                    if (r.data.count > 10) {
                        vueInstance.then(vue => {
                            if (!['notifications'].includes(vue.$route.name)) {
                                vue.$bvModal.show('modalViewNotifications')
                            }
                        })
                    }
                } else {
                    commit('SET_HAS_NO_NOTIFICATIONS')
                }
            }

            commit("SET_IS_QUIZ", r.data.isQuiz)
        })
}

export function doGetNotifications() {
    startLoading()
    return API.auth.getNotifications()
        .then(r => r.data)
        .then((r) => {
            if (!r.data.items) return Promise.reject(r)
            return r.data.items
        })
        .finally(finishLoading)
}

export function doGetClosedOrders() {
    startLoading()
    return API.auth.getClosedOrders()
        .then(r => r.data)
        .then(r => {
            if (r.status !== undefined && r.status) {
                return r.data
            }
            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doGetActiveOrders() {
    startLoading()
    return API.auth.getActiveOrders()
        .then(r => r.data)
        .then(r => {
            if (r.status !== undefined && r.status) {
                return r.data
            }
            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doGetOrdersList(ctx, {status, period}) {
    console.log(status)
    startLoading()
    return API.auth.getOrdersList(status, period)
        .then(r => r.data)
        .then(r => {
            if (r.status !== undefined && r.status) {
                return r.data
            }
            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doGetActionHistory() {
    startLoading()
    return API.auth.getActionHistory()
        .then(r => r.data)
        .then(r => {
            if (r.status !== undefined && r.status) {
                return r.data.items
            }
            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doGetStatusDetails(ctx, {incident, id, type}) {
    startLoading()
    return API.auth.getStatusDetails(incident, id, type)
        .then(r => r.data)
        .finally(finishLoading)
}

export function doMarkNotifications(ctx, {guids}) {
    startLoading()
    return API.auth.markNotifications(guids)
        .then(r => r.data)
        .finally(finishLoading)
}

export function doGetStatusHistory() {
    startLoading()
    return API.auth.getStatusHistory()
        .then(r => r.data)
        .then(r => {
            if (r.status !== undefined && r.status) {
                return r.data
            }
            return Promise.reject(r)
        })
        .finally(finishLoading)
}

export function doGetUnblockSms(ctx, {phone, login}) {
    startLoading()
    return API.auth.getUnblockSms(phone, login)
        .then((r => r.data))
        .then((r) => {
            console.log(r)
            if (r.data.status) { // !!! r.data.status NOT r.status as usual
                return Promise.resolve(r)
            } else {
                return Promise.reject(r)
            }
        })
        .finally(finishLoading)
}

export function doUnblockUser(ctx, {code, publicCode}) {
    startLoading()
    return API.auth.unblockUser(code, publicCode)
        .then((r => r.data))
        .then((r) => {
            console.log(r)
            if (r.data.status) { // !!! r.data.status NOT r.status as usual
                return Promise.resolve(r)
            } else {
                return Promise.reject(r)
            }
        })
        .finally(finishLoading)
}

export function userAuthFail({commit}, errData) {
    commit('RESET_USER')
    commit('ERROR', errData)
}

export function userAuthSuccess({commit}, sessionParams) {
    commit('SET_USER_LOGGED', sessionParams)
    commit('CLEAR_ERROR')
}

export function adminAuthSuccess({commit}) {
    commit('SET_ADMIN_LOGGED')
    commit('CLEAR_ERROR')
}

export function userPassChanged({commit}) {
    commit('PASS_CHANGED')
}

