import { render, staticRenderFns } from "./viewingUniteRequests_more.vue?vue&type=template&id=27bf29b0&scoped=true&"
import script from "./viewingUniteRequests_more.vue?vue&type=script&lang=js&"
export * from "./viewingUniteRequests_more.vue?vue&type=script&lang=js&"
import style0 from "./viewingUniteRequests_more.vue?vue&type=style&index=0&id=27bf29b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27bf29b0",
  null
  
)

export default component.exports