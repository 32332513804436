<template>
    <div id="app">
        <div v-if="!isNeedAnyAuthActions">
            <Header />
            <router-view />
            <Footer/>
            <BurgerMenu/>
        </div>
        <router-view v-else :to="{name: 'auth'}" />

        <Socket :isNeedAnyAuthActions = isNeedAnyAuthActions />
        <ModalErrorDefault />
        <Loader />
    </div>
</template>

<script>

    import Header from "@/components/Header";
    import Footer from "@/components/Footer";
    import Loader from "@/components/modals/Loader";
    import BurgerMenu from "@/components/modals/BurgerMenu";
    import ModalErrorDefault from "@/components/modals/errors/ModalErrorDefault";
    import {mapActions, mapGetters} from "vuex";
    import update from './mixins/update'
    import Socket from "@/components/Socket.vue";

    export default {
        name: 'App',
        components: {
            Socket,
            Header,
            Footer,
            BurgerMenu,
            Loader,
            ModalErrorDefault
        },
        methods: {
            ...mapActions('user', {loadSession: 'loadUserSession'})
        },
        computed: {
            ...mapGetters('user', {isNeedAnyAuthActions: 'isNeedAnyAuthActions'}),
        },
        mixins: [update],
        created() {
            //this.loadSession()
            //if (this.isNeedAnyAuthActions) this.$router.push( {name: 'auth'} )
            if (navigator.userAgent.indexOf('Mac') > 0 && window.innerWidth > 767) {
                document.getElementsByTagName('body')[0].className += " mac-os"
            }
        }
    }
</script>

<style lang="scss">
    // bootstrap
    @import '../node_modules/bootstrap/scss/bootstrap.scss';
    @import '../node_modules/bootstrap-vue/src/index.scss';
    // шрифт
    @import "./assets/fonts/fonts.css";
    // обнуление
    @import "./assets/scss/null.scss";
    // цвета
    @import "./assets/scss/colors.scss";
    // общий
    @import "./assets/scss/template.scss";
</style>
